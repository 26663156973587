import { useService } from "@xstate/react";
import Decimal from "decimal.js-light";
import React from "react";

import questionMark from "../../images/ui/expression_confused.png"
import coin from "../../images/ui/icon.png";
import {
  BlockchainEvent,
  BlockchainState,
  Context,
  service,
} from "../../machine";
import {
  Action,
  ACTIONABLE_ITEMS,
  ActionableItem,
  Element,
  Square,
  Transaction,
} from "../../types/contract";
import { ELEMENTS, ElementItem, getMarketElements } from "../../types/elements";
import {
  cacheAccountFarm,
  getSelectedItem,
} from "../../utils/localStorage";
import { getMarketRate } from "../../utils/supply";
import { AudioPlayer } from "../ui/AudioPlayer";
import { Button } from "../ui/Button";
import { Message } from "../ui/Message";
import { Panel } from "../ui/Panel";
import { Timer } from "../ui/Timer";
import { Board } from "./Board";
import { Land } from "./Land";
import { Tuto } from "./Tuto";
import { wiki, quickswap } from '../../../constants';

export const Farm: React.FC = () => {
  const [balance, setBalance] = React.useState<Decimal>(new Decimal(0));
  const [land, setLand] = React.useState<Square[]>(
    Array(5).fill({
      element: Element.None,
      createdAt: 0,
    })
  );

  /*HERE*/
  const [newPrice, setNewPrice] = React.useState(0);
  const handleNewPriceChange = (val) => {
    setNewPrice(val);
  }
  const [newPrice1, setNewPrice1] = React.useState(0);
  const handleNewPriceChange1 = (val) => {
    setNewPrice1(val);
  }
  const [newPrice2, setNewPrice2] = React.useState(0);
  const handleNewPriceChange2 = (val) => {
    setNewPrice2(val);
  }
  const [newPrice3, setNewPrice3] = React.useState(0);
  const handleNewPriceChange3 = (val) => {
    setNewPrice3(val);
  }
  const [newdonation, setNewDonation] = React.useState(0);
  const handleNewDonation = (val) => {
    setNewDonation(val);
  }

  const [newFirstElementGain, setNewFirstElementGain] = React.useState(0);
  const handleNewFirstElementGainChange = (val) => {
    setNewFirstElementGain(val);
  }

  const [newSecondElementGain, setNewSecondElementGain] = React.useState(0);
  const handleNewSecondElementGainChange = (val) => {
    setNewSecondElementGain(val);
  }

  const [newThirdElementGain, setNewThirdElementGain] = React.useState(0);
  const handleNewThirdElementGainChange = (val) => {
    setNewThirdElementGain(val);
  }

  const [newFourthElementGain, setNewFourthElementGain] = React.useState(0);
  const handleNewFourthElementGainChange = (val) => {
    setNewFourthElementGain(val);
  }

  const [newFifthElementGain, setNewFifthElementGain] = React.useState(0);
  const handleNewFifthElementGainChange = (val) => {
    setNewFifthElementGain(val);
  }

  const [newSixthElementGain, setNewSixthElementGain] = React.useState(0);
  const handleNewSixthElementGainChange = (val) => {
    setNewSixthElementGain(val);
  }

  const [newSeventhElementGain, setNewSeventhElementGain] = React.useState(0);
  const handleNewSeventhElementGainChange = (val) => {
    setNewSeventhElementGain(val);
  }

  // États pour stocker les valeurs des prix des upgrades et des gains des éléments
  const [upgradePrices,setUpgradePrices] = React.useState({
    first: 0,
    second: 0,
    third: 0,
    fourth: 0
  });

  const [elementGains,setElementGains] = React.useState({
    first: 0,
    second: 0,
    third: 0,
    fourth: 0,
    fifth: 0,
    sixth: 0,
    seventh: 0
  });

  const fetchUpgradePrices = async () => {
    try {
      const prices = await machineState.context.blockChain.getUpgradePrices();
      setUpgradePrices(prices);
    } catch (error) {
      console.error("Error fetching upgrade prices: ", error);
    }
  };

  const fetchElementGains = async () => {
    try {
      const gains = await machineState.context.blockChain.getElementGains();
      setElementGains(gains);
    } catch (error) {
      console.error("Error fetching element gains: ", error);
    }
  };

  const [isZoneAdminVisible, setIsZoneAdminVisible] = React.useState(false);

  const toggleZoneAdminVisibility = () => {
    setIsZoneAdminVisible(!isZoneAdminVisible);
  };




  const [supply, setSupply] = React.useState<any>({
    statue: 0,
  });

  const farmIsFresh = React.useRef(false);
  const accountId = React.useRef<string>();
  const [selectedItem, setSelectedItem] = React.useState<ActionableItem>(
    ACTIONABLE_ITEMS[0]
  );
  const [elements, setElements] = React.useState<ElementItem[]>(ELEMENTS);
  const [elementPrices, setElementPrices] = React.useState<ElementItem[]>([]);
  const [machineState, send] = useService<
    Context,
    BlockchainEvent,
    BlockchainState
  >(service);

  const isDirty = machineState.context.blockChain.isUnsaved();

  // If they have unsaved changes, alert them before leaving
  React.useEffect(() => {
    window.onbeforeunload = function (e) {
      if (!isDirty) {
        return undefined;
      }
      e = e || window.event;

      // For IE and Firefox prior to version 4
      if (e) {
        e.returnValue = "Sure?";
      }

      // For Safari
      return "Sure?";
    };
  }, [isDirty, machineState]);

  React.useEffect(() => {
    const load = async () => {
      const isFarming =
        machineState.matches("farming") ||
        machineState.matches("onboarding");

      const doRefresh = !farmIsFresh.current;

      // HACK: Upgrade modal does not upgrade balance and farm so mark farm as stale
      if (
        machineState.matches("upgrading") ||
        machineState.matches("loading") ||
        machineState.matches("rewarding")
      ) {
        farmIsFresh.current = false;
      }

      // Load fresh data from blockchain only if there are no unsaved changes
      if (
        isFarming &&
        doRefresh &&
        !machineState.context.blockChain.isUnsaved()
      ) {
        const {
          farm,
          balance: currentBalance,
          id,
        } = machineState.context.blockChain.myFarm;
        setLand(farm);
        setBalance(new Decimal(currentBalance));
        farmIsFresh.current = true;
        accountId.current = id;

        const cachedItem = getSelectedItem(id);
        setSelectedItem(cachedItem);

        const supply = await machineState.context.blockChain.totalSupply();
        const marketRate = getMarketRate(supply);
        await machineState.context.blockChain.cacheElementGains();
      const elementGains = machineState.context.blockChain.getCachedElementGains();
      if (!elementGains || !elementGains.first) {
        console.error("Element gains are not available");
        return;
              }

        const marketElements = getMarketElements({ marketRate, elementGains });
        setElementPrices(marketElements); // Utilisez elementPrices au lieu de elements
        

      }
    };

    load();
  }, [machineState,elementGains]);

  const onChangeItem = (item: ActionableItem) => {
    setSelectedItem(item);


    cacheAccountFarm(accountId.current, { selectedItem: item.name });

  };
  const onHarvest = React.useCallback(
    async (landIndex: number) => {
      const now = Math.floor(Date.now() / 1000);

      const harvestedFruit = land[landIndex];
      const transaction: Transaction = {
        action: Action.Harvest,
        element: Element.None,
        landIndex,
        createdAt: now,
      };
      machineState.context.blockChain.addEvent(transaction);

      setLand((oldLand) =>
        oldLand.map((field, index) =>
          index === landIndex ? { element: Element.None, createdAt: 0 } : field
        )
      );

      const element = elementPrices.find(
        (item) => item.element === harvestedFruit.element
      );
  
      if (element) {
        const price = element.sellPrice;
        setBalance(balance.plus(price));
      } else {
        console.error(`Element not found for harvestedFruit.element: ${harvestedFruit.element}`);
      }

      // const price = elementPrices.find(
      //   (item) => item.element === harvestedFruit.element
      // ).sellPrice;

      // setBalance(balance.plus(price));

      send("HARVEST");
    },
    [balance, elementPrices, land, machineState.context.blockChain, send]
  );

  const onPlant = React.useCallback(
    async (landIndex: number) => {
      const price = elements.find(
        (item) => item.element === selectedItem.element
      ).buyPrice;

      if (balance.lt(price)) {
        return;
      }

      const now = Math.floor(Date.now() / 1000);
      const transaction: Transaction = {
        action: Action.Plant,
        element: selectedItem.element,
        landIndex,
        createdAt: now,
      };
      machineState.context.blockChain.addEvent(transaction);

      setLand((oldLand) => {
        const newLand = oldLand.map((field, index) =>
          index === landIndex ? transaction : field
        );
        return newLand;
      });
      setBalance(balance.minus(price));

      send("PLANT");
    },
    [balance, selectedItem, elements, machineState.context.blockChain, send]
  );

  const onBuyMore = () => {
    window.open(
      quickswap,
      "_blank"
    );
  };

  const save = async () => {
    send("SAVE", { action: "SYNC" });
  };

  const safeBalance = balance.toNumber();

  //Mobile
  const isMobile = machineState.context.blockChain.isMobile();

  return (
    <>
      <Tuto />
      <Land
        elements={elements}
        selectedItem={selectedItem}
        land={land}
        balance={safeBalance}
        onHarvest={onHarvest}
        onPlant={onPlant}
        account={accountId.current}
      />
      <AudioPlayer />
      <span id="save-button">
        <Panel hasInner={false}>
          <Button
            onClick={save}
            disabled={
              !isDirty ||
              machineState.matches("timerComplete") ||
              machineState.matches("saving")
            }
          >
            Save
            {isDirty && (
              <Timer
                startAtSeconds={machineState.context.blockChain.lastSaved()}
              />
            )}
          </Button>
          <div id="aboutbutton">
            <Button onClick={() => window.open(wiki)}>
              About
              <img src={questionMark} id="question" />
            </Button>
          </div>
        </Panel>
      </span>

      {/* HERE */}

      <div>
      {machineState.context.blockChain.WhoIsConnected() === machineState.context.blockChain.GetOwnerWallet() && (
        <button onClick={toggleZoneAdminVisibility} style={{ position: 'fixed', top: '10px', left: '10px', zIndex: 778 }}>
          {isZoneAdminVisible ? '▲' : '▼'}
        </button>
        )}
        {
          machineState.context.blockChain.WhoIsConnected() === machineState.context.blockChain.GetOwnerWallet() ?
            (
              isZoneAdminVisible && (
                <span id="ZoneAdmin">
                  <Panel hasInner={true}>
                    Bienvenue cher Admin !
                    <div id="Burn">
                      <Button
                        onClick={() => machineState.context.blockChain.changeburnUpgrade(true)}>
                        Burn On
                      </Button>
                      <Button
                        onClick={() => machineState.context.blockChain.changeburnUpgrade(false)}>
                        Burn OFF
                      </Button>
                    </div>

                    <div id="Global">
                      <div id="gauche">
                        <Button
                          onClick={() => machineState.context.blockChain.changeDonationValue(newdonation)}>
                          Change Donation Price (25 for 2.5) :
                        </Button>
                      </div>
                      <input className="droite small-input" id="droite" type="text" value={newdonation} onChange={(e) => handleNewDonation(e.target.value)} />
                    </div>

                    <div id="Global">
                      <div id="gauche">
                        <Button
                          onClick={() => machineState.context.blockChain.changeFirstUpgradePrice(newPrice)}>
                          Change 1st Upgrade (float):
                        </Button>
                      </div>
                      <input className="droite small-input" id="droite" type="text" value={newPrice} onChange={(e) => handleNewPriceChange(e.target.value)} />
                    </div>

                    <div id="Global">
                      <div id="gauche">
                        <Button
                          onClick={() => machineState.context.blockChain.changeSecondUpgradePrice(newPrice1)}>
                          Change 2nd Upgrade (float) :
                        </Button>
                      </div>
                      <input className="droite small-input" id="droite" type="text" value={newPrice1} onChange={(e) => handleNewPriceChange1(e.target.value)} />
                    </div>

                    <div id="Global">
                      <div id="gauche">
                        <Button
                          onClick={() => machineState.context.blockChain.changeThirdUpgradePrice(newPrice2)}>
                          Change 3nd Upgrade (float) :
                        </Button>
                      </div>
                      <input className="droite small-input" id="droite" type="text" value={newPrice2} onChange={(e) => handleNewPriceChange2(e.target.value)} />
                    </div>

                    <div id="Global">
                      <div id="gauche">
                        <Button
                          onClick={() => machineState.context.blockChain.changeFourUpgradePrice(newPrice3)}>
                          Change 4nd Upgrade (float) :
                        </Button>
                      </div>
                      <input className="droite small-input" id="droite" type="text" value={newPrice3} onChange={(e) => handleNewPriceChange3(e.target.value)} />
                    </div>

                    <div id="Global">
                      <div id="gauche">
                        <Button onClick={() => machineState.context.blockChain.changeFirstElementGain(newFirstElementGain)}>
                          Change 1st Element Gain (175 for 0.0175) :
                        </Button>
                      </div>
                      <input className="droite small-input" id="droite" type="text" value={newFirstElementGain} onChange={(e) => handleNewFirstElementGainChange(e.target.value)} />
                    </div>

                    <div id="Global">
                      <div id="gauche">
                        <Button onClick={() => machineState.context.blockChain.changeSecondElementGain(newSecondElementGain)}>
                          Change 2nd Element Gain (1375 for 0.1375) :
                        </Button>
                      </div>
                      <input className="droite small-input" id="droite" type="text" value={newSecondElementGain} onChange={(e) => handleNewSecondElementGainChange(e.target.value)} />
                    </div>

                    <div id="Global">
                      <div id="gauche">
                        <Button onClick={() => machineState.context.blockChain.changeThirdElementGain(newThirdElementGain)}>
                          Change 3nd Element Gain (88 for 0.88):
                        </Button>
                      </div>
                      <input className="droite small-input" id="droite" type="text" value={newThirdElementGain} onChange={(e) => handleNewThirdElementGainChange(e.target.value)} />
                    </div>

                    <div id="Global">
                      <div id="gauche">
                        <Button onClick={() => machineState.context.blockChain.changeFourElementGain(newFourthElementGain)}>
                          Change 4nd Element Gain (27 for 2.7)  :
                        </Button>
                      </div>
                      <input className="droite small-input" id="droite" type="text" value={newFourthElementGain} onChange={(e) => handleNewFourthElementGainChange(e.target.value)} />
                    </div>

                    <div id="Global">
                      <div id="gauche">
                        <Button onClick={() => machineState.context.blockChain.changeFiveElementGain(newFifthElementGain)}>
                          Change 5nd Element Gain (8 for 8) :
                        </Button>
                      </div>
                      <input className="droite small-input" id="droite" type="text" value={newFifthElementGain} onChange={(e) => handleNewFifthElementGainChange(e.target.value)} />
                    </div>

                    <div id="Global">
                      <div id="gauche">
                        <Button onClick={() => machineState.context.blockChain.changeSixElementGain(newSixthElementGain)}>
                          Change 6nd Element Gain (21 for 21) :
                        </Button>
                      </div>
                      <input className="droite small-input" id="droite" type="text" value={newSixthElementGain} onChange={(e) => handleNewSixthElementGainChange(e.target.value)} />
                    </div>

                    <div id="Global">
                      <div id="gauche">
                        <Button onClick={() => machineState.context.blockChain.changeSevenElementGain(newSeventhElementGain)}>
                          Change 7nd Element Gain  (90 for 90) :
                        </Button>
                      </div>
                      <input className="droite small-input" id="droite" type="text" value={newSeventhElementGain} onChange={(e) => handleNewSeventhElementGainChange(e.target.value)} />
                    </div>

                    <div className="global">
                    <Button onClick={() => fetchUpgradePrices()}>
                        Get Upgrade Prices
                      </Button>
                      <div className="prices-display">
                        <p>{upgradePrices.first};{upgradePrices.second};{upgradePrices.third};{upgradePrices.fourth}</p>
                      </div>
                    </div>

                    {/* Button and section to fetch and display element gains */}
                    <div className="global">
                      <Button onClick={() => fetchElementGains()}>
                        Get Element Gains
                      </Button>
                      <div className="gains-display">
                        <p>{elementGains.first};{elementGains.second};{elementGains.third};{elementGains.fourth};{elementGains.fifth};{elementGains.sixth};{elementGains.seventh}</p>
                        
                      </div>
                    </div>

                  </Panel>
                </span>
              )
            )
            : (//change ici
              <span id="ZoneOff">
                You dont have access here
              </span>
            )
        }
      </div>


      {/*FIN HERE */}

      <div id="balance">
        <Panel>
          <div id="inner">
            <img src={coin} />
            {machineState.context.blockChain.isConnected &&
              safeBalance.toFixed(3)}
          </div>
        </Panel>
      </div>

      <div id="island-name">
        <Panel>
          <div id="inner">CatFarm</div>
        </Panel>
      </div>

      <div id="buy-now" onClick={onBuyMore}>
        <Message>Quickswap</Message>
      </div>

      <Board
        elements={elements}
        selectedItem={selectedItem}
        onSelectItem={onChangeItem}
        land={land}
        balance={safeBalance}
      />
    </>
  );
};

export default Farm;
