import { assign, createMachine, interpret, Interpreter } from "xstate";
import { BlockChain } from "./Blockchain";
import { TeamAdress } from "./types/contract";
import { hasOnboarded } from "./utils/localStorage";
import { isNearHalvening } from "./utils/supply";

export interface Context {
  blockChain: BlockChain;
  errorCode?: "NO_WEB3" | "WRONG_CHAIN";
  gasPrice?: number;
}

const hasFarm = ({ blockChain }: Context) => {
  return blockChain.isTrial || blockChain.hasFarm;
};

const MOBILE_DEVICES = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

const isMobile = () => {
  return MOBILE_DEVICES.test(navigator.userAgent);
};

export type BlockchainEvent =
  | { type: "FARM_CREATED" }
  | { type: "NETWORK_CHANGED" }
  | { type: "GET_STARTED" }
  | { type: "SAVE"; action: "SYNC" | "UPGRADE" }
  | { type: "RETRY" }
  | { type: "TRIAL" }
  | { type: "TIMER_COMPLETE" }
  | { type: "DONATE"; donation: { teamAdress: TeamAdress; value: string } }
  | { type: "FINISH" }
  | { type: "CLOSE" }
  | { type: "NEXT" }
  | { type: "HARVEST" }
  | { type: "PLANT" }
  | { type: "CHOP"; resource: string; amount: number }
  | { type: "MINE"; resource: string; amount: number }
  | { type: "ACCOUNT_CHANGED" }
  | { type: "OPEN_REWARD" }
  | { type: "CANCEL" };

  export type OnboardingStates = "harvesting" | "token" | "planting" | "saving" | "market";

export type BlockchainState = {
  value: 
    | "loading"
    | "initial"
    | "registering"
    | "creating"
    | "onboarding"
    | "farming"
    | "failure"
    | "upgrading"
    | "rewarding"
    | "saving"
    | "warning"
    | "confirming"
    | "crafting"
    | "chopping"
    | "collecting"
    | "mining"
    | "timerComplete"
    | "unsupported"
    | "saveFailure"
    | "harvesting"
    | "token"
    | "planting"
    | "market";
  context: Context;
};

export type BlockchainInterpreter = Interpreter<Context, any, BlockchainEvent, BlockchainState>;

export const blockChainMachine = createMachine<Context, BlockchainEvent, BlockchainState>({
  id: "farmMachine",
  initial: "initial",
  context: {
    blockChain: new BlockChain(),
    errorCode: null,
  },
  states: {
    initial: {
      on: {
        GET_STARTED: "loading",
      },
    },
    loading: {
      invoke: {
        src: ({ blockChain }) => blockChain.initialise(),
        onDone: [
          { target: "farming", cond: hasFarm },
          { target: "registering" },
        ],
        onError: {
          target: "failure",
          actions: assign({
            errorCode: (_, event) => event.data.message,
          }),
        },
      },
    },
    registering: {
      on: {
        DONATE: "creating",
        NETWORK_CHANGED: "loading",
      },
    },
    creating: {
      invoke: {
        src: ({ blockChain }, event) => blockChain.createFarm((event as any).donation),
        onDone: "onboarding",
        onError: {
          target: "registering",
          actions: assign({
            errorCode: (_, event) => event.data.message,
          }),
        },
      },
    },
    onboarding: {
      initial: "harvesting",
      states: {
        harvesting: {
          on: { HARVEST: "token" },
        },
        token: {
          on: { NEXT: "planting" },
        },
        planting: {
          on: { PLANT: "saving" },
        },
        saving: {
          on: { NEXT: "market" },
        },
        market: {},
      },
      on: {
        FINISH: "farming",
        CLOSE: "farming",
      },
    },
    farming: {
      on: {
        SAVE: "saving",
        OPEN_REWARD: "rewarding",
        CHOP: "chopping",
        MINE: "mining",
        TIMER_COMPLETE: "timerComplete",
        ACCOUNT_CHANGED: {
          target: "loading",
          actions: (context) => context.blockChain.resetFarm(),
        },
        NETWORK_CHANGED: {
          target: "loading",
          actions: (context) => context.blockChain.resetFarm(),
        },
      },
    },
    warning: {
      on: {
        SAVE: [
          { cond: (_, event) => event.action === "UPGRADE", target: "upgrading" },
          { target: "confirming" },
        ],
      },
    },
    saving: {
      invoke: {
        src: async ({ blockChain }, event) => {
          const estimate = await blockChain.estimate();
          return { estimate, action: (event as any).action };
        },
        onDone: [
          {
            cond: (context, event) => {
              if (isNearHalvening(context.blockChain.totalSupply())) return true;
              if (!hasOnboarded()) return true;
              return event.data.estimate > 40000000000;
            },
            target: "warning",
            actions: assign({
              gasPrice: (_, event) => event.data.estimate,
            }),
          },
          { cond: (_, event) => event.data.action === "UPGRADE", target: "upgrading" },
          { target: "confirming" },
        ],
        onError: {
          target: "failure",
          actions: assign({
            errorCode: (_, event) => event.data.message,
          }),
        },
      },
    },
    confirming: {
      invoke: {
        src: async ({ blockChain }) => blockChain.save(),
        onDone: "farming",
        onError: {
          target: "saveFailure",
          actions: assign({
            errorCode: (_, event) => event.data.message,
          }),
        },
      },
    },
    upgrading: {
      invoke: {
        src: async ({ blockChain }) => blockChain.levelUp(),
        onDone: "farming",
        onError: {
          target: "failure",
          actions: assign({
            errorCode: (_, event) => event.data.message,
          }),
        },
      },
    },
    rewarding: {
      invoke: {
        src: async ({ blockChain }) => blockChain.receiveReward(),
        onDone: "farming",
        onError: {
          target: "failure",
          actions: assign({
            errorCode: (_, event) => event.data.message,
          }),
        },
      },
    },
    chopping: {
      invoke: {
        src: async ({ blockChain }, event) => blockChain.stake(event as any),
        onDone: "farming",
        onError: {
          target: "failure",
          actions: assign({
            errorCode: (_, event) => event.data.message,
          }),
        },
      },
    },
    mining: {
      invoke: {
        src: async ({ blockChain }, event) => blockChain.stake(event as any),
        onDone: "farming",
        onError: {
          target: "failure",
          actions: assign({
            errorCode: (_, event) => event.data.message,
          }),
        },
      },
    },
    failure: {
      on: {
        NETWORK_CHANGED: {
          target: "loading",
          actions: (context) => context.blockChain.endTrialMode(),
        },
        TRIAL: {
          target: "onboarding",
          actions: (context) => context.blockChain.startTrialMode(),
        },
      },
    },
    saveFailure: {
      on: {
        SAVE: {
          target: "saving",
          actions: (context) => context.blockChain.offsetTime(),
        },
        CLOSE: "farming",
      },
    },
    timerComplete: {
      on: {
        SAVE: {
          target: "saving",
          actions: (context) => context.blockChain.offsetTime(),
        },
      },
    },
    unsupported: {},
  },
});

export const service = interpret(blockChainMachine);
service.start();
